body,
html {
  width: 100%;
  height: 100%;
  background: $bg-color;
  // overflow: hidden;
}

body {
  color: $color-black-01;
  font-family: $ft-roboto;
  font-size: 14px;
  font-weight: $ft-light;
  -webkit-font-smoothing: antialiased;

  &.js-overflow-hidden {
    overflow: hidden;
  }
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

.App {
  padding-top: 30px;
  overflow: hidden;
}

.dark-mode {
  color: white;
  background-color: #010b1a !important;

  *[class^='wrapper'] {
    background-color: #010b1a !important;
  }
}
