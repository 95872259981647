/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer; }

.title {
  font-size: 15vw;
  font-family: "lustprodidone-no1uploadedfile", serif;
  color: black;
  line-height: 200px;
  display: block;
  width: 100vw;
  transition: color 0.6s;
  position: relative;
  text-transform: capitalize;
  user-select: none; }
  .title div {
    display: inline-block; }
  @media (min-width: 768px) {
    .title {
      font-size: 15vw; } }
  @media (min-width: 1240px) {
    .title {
      font-size: 170px; } }
  @media (min-width: 1400px) {
    .title {
      font-size: 170px; } }
  @media (min-width: 1600px) {
    .title {
      font-size: 200px; } }

.titleShadow {
  font-size: 20vw;
  font-family: "lustprodidone-no1uploadedfile", serif;
  color: black;
  line-height: 240px;
  display: block;
  width: 100vw;
  transition: color 0.6s;
  position: absolute; }
  .titleShadow div {
    display: inline-block; }
  @media (min-width: 1240px) {
    .titleShadow {
      font-size: 220px; } }

.isTransparent {
  color: white; }
