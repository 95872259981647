/*
  Import normalize from npm
*/
@import '~normalize.css';

/* stylelint-disable */
*,
::before,
::after {
  box-sizing: border-box;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none;
}

figure {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  display: block;
}

button {
  background-color: transparent;
  cursor: pointer;
}

li {
  list-style-type: none;
}

address {
  font-style: normal;
}

input,
textarea,
select {
  font-size: 14px;
}

input,
textarea,
button {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset;
  background-color: transparent;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

a,
span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: inherit;
}
