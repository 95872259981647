/* stylelint-disable-next-line block-no-empty */
$ft-roboto: 'Roboto', sans-serif;
$ft-extra-light: 100;
$ft-light: 300;
$ft-regular: 400;

$ft-lust: 'lustprodidone-no1uploadedfile', serif;
$ft-lust-bold: 'lustprodidone-no2uploadedfile', serif;
$ft-andale: 'andale_monoregular', sans-serif;
$ft-helvetica: 'Helvetica, Arial' sans-serif;
