/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  opacity: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  @media (min-width: 375px) {
    .wrapper {
      flex-direction: row; } }

@media (min-width: 375px) {
  .wrapperLeft {
    width: 40%;
    align-self: center; } }

@media (min-width: 1400px) {
  .wrapperLeft {
    width: 50%; } }

.wrapperRight {
  font-family: "Helvetica, Arial" sans-serif;
  padding-left: 40px; }
  @media (min-width: 375px) {
    .wrapperRight {
      align-self: center;
      width: 50%; } }

.number {
  font-size: 60vw;
  pointer-events: none;
  color: black;
  font-family: "lustprodidone-no1uploadedfile", serif;
  margin-left: -100px; }
  @media (min-width: 375px) {
    .number {
      font-size: 40vw;
      margin-left: -20vw; } }
  @media (min-width: 1400px) {
    .number {
      font-size: 600px;
      margin-left: -250px; } }

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .title {
      font-size: 70px; } }

.subTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 50px;
  max-width: 310px; }
  @media (min-width: 768px) {
    .subTitle {
      font-size: 25px; } }

.bottomWrapper {
  display: flex;
  font-family: "andale_monoregular", sans-serif;
  max-width: 500px; }

.keywords {
  width: 50%;
  font-size: 13px; }
  .keywords li {
    margin-bottom: 10px; }
