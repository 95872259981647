/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.mouseCursor {
  pointer-events: none;
  cursor: none;
  top: 0;
  left: 0;
  z-index: 1000; }
  .mouseCursor span {
    font-family: "andale_monoregular", sans-serif;
    font-size: 3px;
    color: black;
    width: 10px;
    height: 10px;
    word-wrap: break;
    position: fixed;
    display: block;
    line-height: 5px;
    text-align: center;
    text-transform: uppercase;
    top: 52%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    user-select: none;
    z-index: 100001; }

.cursorBall {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
  transform: translateX(50%) translateY(50%); }
  .cursorBall circle {
    fill: white; }

.ballSmall {
  opacity: 1;
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0; }
  .ballSmall circle {
    fill: red; }
