/**
 * Mobile first
*/

@mixin mediaMin375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mediaMin410 {
  @media (min-width: 410px) {
    @content;
  }
}

@mixin mediaMin640 {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin mediaMin768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mediaMin1024 {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mediaMin1240 {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin mediaMin1400 {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin mediaMin1600 {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin mediaMin1920 {
  @media (min-width: 1920px) {
    @content;
  }
}

/**
   * !Mobile first
  */

@mixin mediaMax360 {
  @media (max-width: 359px) {
    @content;
  }
}

@mixin mediaMax420 {
  @media (max-width: 319px) {
    @content;
  }
}

@mixin mediaMax768 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mediaMax1024 {
  @media (max-width: 1023px) {
    @content;
  }
}

/**
   * Media min-max
  */
@mixin media7681024 {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

/**
   * Aspect ratio
  */
@mixin mediaLandscape {
  @media (min-aspect-ratio: 3/2) {
    @content;
  }
}
