/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
@media (max-width: 767px) {
  .wrapper {
    overflow-x: hidden;
    overflow-y: scroll; } }

@media (min-width: 768px) {
  .wrapper > div {
    display: flex;
    align-items: center;
    width: 100vw;
    height: calc(100vh - 30px); }
    .wrapper > div::-webkit-scrollbar {
      width: 0;
      background: transparent; }
    .wrapper > div::-webkit-scrollbar-thumb {
      background: transparent; } }

.list {
  display: flex; }
  @media (max-width: 767px) {
    .list {
      flex-direction: column;
      width: 100%; } }
  @media (min-width: 768px) {
    .list {
      flex-wrap: nowrap;
      overflow-x: auto;
      scroll-behavior: smooth; }
      .list::-webkit-scrollbar {
        width: 0;
        background: transparent; }
      .list::-webkit-scrollbar-thumb {
        background: transparent; } }

.item {
  position: relative;
  font-family: "lustprodidone-no1uploadedfile", serif;
  min-width: 400px;
  height: 300px;
  flex-shrink: 0; }
  @media (max-width: 767px) {
    .item:nth-child(odd) {
      align-self: flex-start;
      margin-left: -40px; }
    .item:nth-child(even) {
      align-self: flex-end;
      margin-right: -40px; } }
  @media (min-width: 768px) {
    .item {
      height: 400px;
      margin-left: 180px; }
      .item:first-child {
        margin-left: -20px; }
      .item:last-child {
        padding-right: 20px; } }
  .item .number {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px; }
    .item .number:before {
      content: '';
      display: inline-block;
      margin-right: 15px;
      width: 66px;
      height: 1px;
      background-color: black; }
  .item .title {
    position: relative;
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 50px;
    transition: color 0.2s ease-in-out; }
    @media (min-width: 768px) {
      .item .title {
        font-size: 90px; } }
  .item:hover .title {
    color: white; }
  .item:hover .clippedImage {
    opacity: 1; }

.path {
  position: absolute;
  width: 0;
  height: 0; }

.clipped {
  position: absolute;
  width: 100%;
  height: 345px;
  z-index: 0; }
  @media (max-width: 767px) {
    .clipped {
      transform: scale(0.7) !important; } }

.clippedImage {
  width: 100%;
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0;
  transform: translateX(0) translateY(0);
  transition: all 0.2s ease-in-out; }
