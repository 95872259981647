/* stylelint-disable-next-line block-no-empty */
$color-black-01: #292929;
$color-black-02: #111111;
$color-white-01: #ffffff;
$color-grey-01: #666666;
$color-grey-02: #303030;
$color-grey-03: #1f1f1f;
$color-red-01: #e90000;

$color-black-logo: #282828;
$color-red-logo: #ff0000;

$bg-color: #fff4e3;
