/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  height: 100%;
  background-color: #fff4e3;
  color: black;
  transition: color 0.3s;
  overflow: hidden;
  transform-origin: 50%; }

.canvas {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: absolute;
  pointer-events: none; }

.guiWrapper {
  z-index: 10000;
  position: absolute; }

.titleWrapper {
  z-index: 0;
  position: absolute;
  top: 40vh;
  left: 20vw;
  pointer-events: none; }

.titleWrapperFront {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 40vh;
  left: 20px;
  transition: opacity 1s; }
  @media (min-width: 768px) {
    .titleWrapperFront {
      left: 20vw; } }

.withOverlay {
  mix-blend-mode: overlay; }

.shadowTitle {
  opacity: 0; }

.arrowLeft {
  top: 75vh;
  left: 20vw;
  position: absolute;
  cursor: pointer;
  z-index: 100; }
  .arrowLeft svg g {
    transition: fill 0.3s; }

.scrollText {
  text-transform: uppercase;
  font-family: "andale_monoregular", sans-serif;
  font-size: 18px;
  display: block;
  position: absolute;
  top: 30vh;
  right: 15vw;
  z-index: 10;
  width: 200px;
  transform: rotate(90deg);
  user-select: none;
  color: inherit;
  opacity: 0;
  transition: opacity 1s; }
  @media (min-width: 768px) {
    .scrollText {
      opacity: 1; } }
