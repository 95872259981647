/* stylelint-disable-next-line block-no-empty */
.text-01 {
  font-size: 11px;
  font-weight: $ft-regular;

  @include mediaMin768 {
    font-size: 16px;
  }

  @include mediaMin1024 {
    font-size: 12px;
  }

  @include mediaMin1600 {
    font-size: 14px;
  }
}

.text-02 {
  font-size: 12px;
  font-weight: $ft-light;
  line-height: 1.66;

  @include mediaMin768 {
    font-size: 18px;
  }

  @include mediaMin1024 {
    font-size: 14px;
  }

  @include mediaMin1600 {
    font-size: 17px;
  }
}

.text-03 {
  font-size: 14px;
  font-weight: $ft-light;
  line-height: 1.66;

  @include mediaMin768 {
    font-size: 20px;
  }

  @include mediaMin1024 {
    font-size: 18px;
  }

  @include mediaMin1600 {
    font-size: 24px;
  }
}

.text-04 {
  font-size: 10px;
  font-weight: $ft-regular;
  line-height: 1.45;

  @include mediaMin768 {
    font-size: 12px;
  }

  @include mediaMin1024 {
    font-size: 10px;
  }

  @include mediaMin1600 {
    font-size: 11px;
  }
}

.text-05 {
  font-size: 14px;
  font-weight: $ft-light;
  line-height: 1.66;

  @include mediaMin768 {
    font-size: 18px;
  }

  @include mediaMin1024 {
    font-size: 16px;
  }

  @include mediaMin1600 {
    font-size: 18px;
  }
}

.text-06 {
  font-size: 12px;
  font-weight: $ft-regular;
  @include mediaMin768 {
    font-size: 18px;
  }

  @include mediaMin1024 {
    font-size: 14px;
  }

  @include mediaMin1600 {
    font-size: 16px;
  }
}

.text-07 {
  font-size: 40px;
  font-weight: $ft-regular;
}

.text-08 {
  font-size: 5vw;
  font-weight: $ft-light;
  line-height: 1.66;

  @include mediaMin768 {
    font-size: 20px;
  }

  @include mediaMin1024 {
    font-size: 18px;
  }
}

.text-09 {
  font-size: 4vw;
  font-weight: $ft-light;
  @include mediaMin768 {
    font-size: 18px;
  }

  @include mediaMin1024 {
    font-size: 16px;
  }
}

.text-10 {
  font-size: 16px;
  font-weight: $ft-light;
  line-height: 1.66;

  @include mediaMin768 {
    font-size: 18px;
  }

  @include mediaMin1024 {
    font-size: 22px;
  }

  @include mediaMin1600 {
    font-size: 24px;
  }
}
