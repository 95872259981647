// Bubbles alignment
.book {
  top: 0;
}

.filtro {
  top: -16px;
  left: 35px;
}

.museum {
  top: -15px;
  left: 60px;
}

.labell {
  top: -16px;
}

.parkit {
  top: -16px;
  left: 65px;
}

.expo {
  top: -5px;
}

.library {
  top: -16px;
  left: 60px;
}
