/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.navigationMobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px;
  z-index: 501;
  transition: all 0.2s ease-in-out;
  pointer-events: none; }
  .navigationMobile .navigationMobileInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    font-family: "andale_monoregular", sans-serif; }
  .navigationMobile .mobileMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    opacity: 0;
    transition: all 0.2s ease-in-out 0.2s; }
  .navigationMobile .hamburgerButton {
    width: 35px;
    height: 25px;
    position: relative;
    border-bottom: 2px solid #292929;
    transition: all 0.2s ease-in-out;
    pointer-events: all; }
    .navigationMobile .hamburgerButton:before, .navigationMobile .hamburgerButton:after {
      content: '';
      width: 35px;
      height: 2px;
      background-color: #292929;
      position: absolute;
      transition: all 0.2s ease-in-out; }
    .navigationMobile .hamburgerButton:before {
      top: 0; }
    .navigationMobile .hamburgerButton:after {
      top: 11px; }
  .navigationMobile.-active {
    background-color: #fff4e3;
    bottom: 0;
    pointer-events: all; }
    .navigationMobile.-active .hamburgerButton {
      border-bottom-color: transparent; }
      .navigationMobile.-active .hamburgerButton:before {
        top: 11px;
        transform: rotate(45deg); }
      .navigationMobile.-active .hamburgerButton:after {
        transform: rotate(-45deg); }
    .navigationMobile.-active .mobileMenu {
      opacity: 1; }
  .navigationMobile .primaryMenu {
    font-size: 22px;
    line-height: 1.5;
    font-family: "andale_monoregular", sans-serif; }
    .navigationMobile .primaryMenu li {
      margin-bottom: 20px; }
  .navigationMobile .secondaryMenu li {
    margin-bottom: 10px; }
  .navigationMobile .secondaryMenu,
  .navigationMobile .copyright {
    font-family: "Helvetica, Arial" sans-serif;
    font-size: 14px;
    line-height: 1.5; }
  .navigationMobile .copyrightEmail {
    font-weight: bold; }
  .navigationMobile .copyrightCol {
    display: flex;
    justify-content: space-between; }
  .navigationMobile .copyrightDate {
    font-family: "andale_monoregular", sans-serif; }
  @media (min-width: 768px) {
    .navigationMobile {
      display: none; } }
