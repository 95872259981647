/* stylelint-disable-next-line block-no-empty */
.title-01 {
  font-size: 40px;
  line-height: 1.3;

  @include mediaMin768 {
    font-size: 60px;
  }

  @include mediaMin1024 {
    font-size: 55px;
  }

  @include mediaMin1600 {
    font-size: 80px;
  }
}

.title-02 {
  font-size: 30px;

  @include mediaMin768 {
    font-size: 50px;
  }

  @include mediaMin1024 {
    font-size: 40px;
  }

  @include mediaMin1600 {
    font-size: 60px;
  }
}

.title-03 {
  font-size: 22px;
  line-height: 1.5;

  @include mediaMin768 {
    font-size: 35px;
  }

  @include mediaMin1024 {
    font-size: 28px;
  }

  @include mediaMin1600 {
    font-size: 40px;
  }
}

.title-04 {
  font-size: 16px;
  font-weight: $ft-extra-light;
  line-height: 1.5;
  @include mediaMin768 {
    font-size: 30px;
  }

  @include mediaMin1024 {
    font-size: 20px;
  }

  @include mediaMin1600 {
    font-size: 40px;
  }
}

.title-04 {
  font-size: 22px;
  font-weight: $ft-light;

  @include mediaMin1024 {
    font-size: 30px;
  }
}
