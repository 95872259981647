/* stylelint-disable-next-line block-no-empty */
// @include fontFace('fontName', 'folderName', 'fileName');
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 2, 2019 */

@font-face {
  font-family: 'lustprodidone-no1uploadedfile';
  src: url('../../asset/font/lustprodidone-no1-webfont.woff2') format('woff2'),
    url('../../asset/font/lustprodidone-no1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lustprodidone-no2uploadedfile';
  src: url('../../asset/font/lustprodidone-no2-webfont.woff2') format('woff2'),
    url('../../asset/font/lustprodidone-no2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'andale_monoregular';
  src: url('../../asset/font/andale_mono-webfont.woff2') format('woff2'),
    url('../../asset/font/andale_mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
