/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  width: 100vw;
  display: block;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .wrapper {
      height: 100vh; } }

.contentWrapper {
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .contentWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100vh;
      padding-bottom: 0; } }

.left {
  width: 100%;
  position: relative; }
  .left h5 {
    font-size: 25px;
    font-family: "Helvetica, Arial" sans-serif;
    font-weight: bold;
    margin-bottom: 40px; }
  .left ul li {
    font-size: 16px;
    font-family: "andale_monoregular", sans-serif;
    text-decoration: underline;
    margin-bottom: 10px; }
  @media (min-width: 768px) {
    .left {
      width: 50%;
      align-self: center; } }

.leftContent {
  max-width: 250px;
  margin-left: 40px; }
  @media (min-width: 768px) {
    .leftContent {
      margin-left: auto;
      margin-right: auto; } }

.right {
  width: 100%;
  position: relative; }
  .right span {
    font-family: "lustprodidone-no1uploadedfile", serif;
    font-size: 65px;
    line-height: 1;
    display: block;
    max-width: 300px; }
  @media (min-width: 768px) {
    .right {
      width: 50%;
      align-self: center; }
      .right span {
        font-size: 95px;
        line-height: 75px; } }

.rightWrapper {
  cursor: pointer;
  margin-left: 40px;
  position: relative;
  max-width: 460px;
  margin-top: 80px; }
  .rightWrapper svg {
    margin-top: 20px; }
  @media (min-width: 768px) {
    .rightWrapper {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0; }
      .rightWrapper svg {
        position: absolute;
        margin-top: 0;
        right: 0px;
        top: 20px; } }
