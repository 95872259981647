/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  margin: 60px 0 0 0;
  padding-bottom: 60px;
  overflowy: scroll; }

.title {
  position: fixed;
  top: 50vh;
  left: 50vw;
  font-family: "lustprodidone-no1uploadedfile", serif;
  font-size: 60px;
  text-transform: uppercase;
  transform: translate(-50%, -50%); }
  @media (min-width: 768px) {
    .title {
      font-size: 120px; } }

.list {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap; }

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 337px; }
  @media (min-width: 768px) {
    .item {
      width: 50%; } }
  @media (min-width: 1024px) {
    .item {
      width: 450px;
      margin-bottom: 160px; } }
  .item.sm .image {
    width: 302px;
    height: 226px; }
  .item.left {
    justify-content: flex-start; }
  .item.right {
    justify-content: flex-end; }
  .item.top {
    align-items: flex-start; }
    .item.top .image {
      transform: translateY(-50%); }
  .item.bottom {
    align-items: flex-end; }
    .item.bottom .image {
      transform: translateY(50%); }
  .item .image {
    display: flex;
    width: 450px;
    height: 100%;
    overflow: hidden; }
    .item .image .image-inner {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      transition: all 0.2s ease-in-out; }
      .item .image .image-inner:hover {
        transform: scale(1.1); }
