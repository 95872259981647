/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/* stylelint-disable-next-line block-no-empty */
/**
 * Asset URL builder
 *
 * @param {String} $type - Asset type, matching folder name
 * @param {String} $file - Asset file name, including extension
 *
 * @return {URL} - A `url()` function leading to the asset
 */
/*
 * Image asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the image
 * @require {function} asset
 */
/*
 * Font asset helper
 * @access public
 * @param {String} $file - Asset file name, including extension
 * @return {URL} - A `url()` function leading to the font
 * @require {function} asset
 */
/**
 * Z-Index function
 *
 * @param {string} $list (Array) - The list to find the current z-index in
 * @param {string} $element (String) - The name of the current element, must be in the $list
 *
 * @returns {number}
 */
/**
 * Computes a topShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Computes a bottomShadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
/**
 * Font Face
 *
 * @param {string} $fontName - Name of the font
 * @param {string} $folderName - Subfolder inside the fonts folder
 * @param {string} $fileName - Name of the font-file
 * @param {string} $fontWeight (normal) - Font weight value
 * @param {string} $fontStyle (normal) - Font style value
 */
/**
 * Font Responsive
 *
 * @param {number} $minFontSize - Min font size
 * @param {number} $maxFontSize - Max font size
 * @param {number} $minViewport - Min viewport size
 * @param {number} $maxViewport - Max viewport size
 */
/**
 * Transition
 *
 * @param {string} $prop
 * @param {duration} $duration (0.75s)
 * @param {duration} $delay (0s)
 * @param {ease} $ease ($easeInOutCubic)
 */
/**
 * Cross Browser Placeholder styles
 *
 * Uses the @content styles within the mixin as stytes
 */
/**
 * Set pseudo elements styles that are used mostly (content, display, position)
 */
/**
 * Mobile first
*/
/**
   * !Mobile first
  */
/**
   * Media min-max
  */
/**
   * Aspect ratio
  */
.wrapper {
  display: block;
  padding: 60px 20px 40px;
  max-width: 1160px;
  margin: 0 auto;
  opacity: 0; }

.textAbout {
  font-family: "andale_monoregular", sans-serif;
  font-size: 14px;
  display: block;
  padding-left: 75px;
  position: relative;
  margin-bottom: 20px; }
  .textAbout:before {
    content: '';
    display: block;
    width: 70px;
    height: 1px;
    position: absolute;
    top: 10px;
    left: 0px;
    background: black; }
  @media (min-width: 560px) {
    .textAbout {
      margin-top: 40px; } }

.heroWrapper {
  margin-bottom: 60px;
  position: relative; }
  .heroWrapper h1 {
    font-family: "lustprodidone-no1uploadedfile", serif;
    font-size: 23vw; }
  .heroWrapper .years {
    font-family: "andale_monoregular", sans-serif;
    font-size: 14px; }
  .heroWrapper .darkMode {
    position: absolute;
    right: 0;
    bottom: 60px;
    width: 116px;
    height: 116px; }
    @media (max-width: 767px) {
      .heroWrapper .darkMode {
        display: none; } }
    .heroWrapper .darkMode:hover {
      animation-duration: 2s;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }
    .heroWrapper .darkMode:before {
      content: '';
      display: block;
      position: absolute;
      top: -2440px;
      left: -2440px;
      width: 5000px;
      height: 5000px;
      border-radius: 50%;
      transform: scale(0.01); }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes dark-grow {
  0% {
    background-color: black;
    transform: scale(0.01); }
  90% {
    background-color: black;
    transform: scale(1); }
  100% {
    background-color: transparent;
    transform: scale(0.01); } }
  @media (min-width: 560px) {
    .heroWrapper h1 {
      font-size: 20vw; }
    .heroWrapper .years {
      position: absolute;
      right: 0;
      display: block;
      top: 0; } }
  @media (min-width: 1200px) {
    .heroWrapper h1 {
      font-size: 220px; } }

.gridTop {
  margin-top: 60px;
  font-family: "Helvetica, Arial" sans-serif;
  font-size: 14px; }
  .gridTop span {
    margin-top: 40px;
    font-weight: bold;
    display: block;
    margin-bottom: 20px; }
  .gridTop p {
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .gridTop {
      display: flex; }
      .gridTop div {
        width: 33.3%;
        display: block; }
        .gridTop div div {
          display: block; }
      .gridTop .twoCols {
        display: flex; }
        .gridTop .twoCols span {
          margin-top: 5px;
          display: block;
          width: 30%;
          padding-left: 20px; }
        .gridTop .twoCols div {
          display: block;
          width: 70%; } }

.gridBottom {
  font: "Helvetica, Arial" sans-serif;
  font-size: 14px; }
  .gridBottom h5 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 30px; }
  .gridBottom a {
    text-decoration: underline; }
  @media (min-width: 768px) {
    .gridBottom {
      display: flex;
      margin-top: 80px; }
      .gridBottom .gridBottomColumn {
        margin: 0 auto 0 0; }
        .gridBottom .gridBottomColumn a {
          max-width: 150px;
          display: block; }
      .gridBottom div {
        width: 17%; }
        .gridBottom div:nth-child(2) {
          width: 32%;
          padding-right: 40px; }
          .gridBottom div:nth-child(2) div {
            width: 100%; } }

.gridBottomColumn ul li {
  margin-bottom: 5px;
  display: block;
  line-height: 20px; }

@media (min-width: 768px) {
  .gridBottomColumn {
    padding: 0 0px; } }

.gridBottomColumnFlex {
  display: flex;
  flex-direction: row; }
  .gridBottomColumnFlex ul {
    display: block;
    width: 70%; }
    .gridBottomColumnFlex ul span {
      font-weight: bold;
      display: block; }
    .gridBottomColumnFlex ul:nth-child(2) {
      width: 30%;
      text-align: right;
      font-weight: bold;
      font-size: 12px; }
    .gridBottomColumnFlex ul li {
      display: block;
      margin-bottom: 10px;
      height: 40px;
      line-height: 20px; }

.bottomContent {
  margin-top: 60px;
  margin-bottom: 60px; }
  .bottomContent h5 {
    font-family: "lustprodidone-no1uploadedfile", serif;
    font-size: 30px;
    margin-bottom: 20px;
    position: relative; }
    .bottomContent h5 span {
      display: none;
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 200px; }
      .bottomContent h5 span svg {
        width: 100%;
        height: auto; }
  .bottomContent a {
    display: block;
    width: 100px;
    font-weight: bold; }
  @media (min-width: 768px) {
    .bottomContent {
      position: relative;
      display: flex; }
      .bottomContent h5 {
        width: 50%;
        font-size: 60px; }
        .bottomContent h5 span {
          display: block;
          width: 300px;
          right: 40px; }
      .bottomContent p {
        width: 40%;
        display: block;
        position: absolute;
        bottom: 40px;
        left: 60%;
        overflow: hidden; } }

.disclaimer {
  font-family: "andale_monoregular", sans-serif;
  font-size: 12px; }
  .disclaimer u {
    text-decoration: underline; }
